<template>
  <div class="product-page">
    <v-row no-gutters>
      <v-col sm="12" class="page-header">
        <span class="page-title">Imports</span>
      </v-col>
      <v-col sm="12" class="mt-4">
        <v-btn outlined color="primary" @click="modalProductreview = true"
          ><i class="fas fa-download mr-1"></i>Import Review</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <modal title="Import product review" model="modalProductreview">
      <template slot="content">
        <div>
          <span
            >Fill in the reviews data you’d like to import and verify that your file meets all the requirements stated
            in the template. </span
          ><br />
          <span>Reviews that do not comply with the instructions will not be imported.</span>
          <v-file-input ref="fileUploader" @change="onChangeImage" accept=".csv" type="file" class="btn btn-default" />
          <!-- <v-card>
            <v-card-actions>
              <v-btn></v-btn>
            </v-card-actions>
            <v-card-subtitle></v-card-subtitle>
          </v-card> -->
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalProductreview: false,
    };
  },
  methods: {
    async onChangeImage(e) {
      // this.formData = null;
      // this.fileName = 'No file chosen';
      // let file = e;
      // this.formData = new FormData();
      // this.formData.append('file', file);
      // this.fileName = e.name;
      console.log(e);
    },
  },
};
</script>

<style></style>
